body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.App {
  text-align: center;
  background: url('/public/6.webp') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.App-content {
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  max-height: 80vh; /* This will define the height of the container */
  overflow-y: auto; /* Allow vertical scrolling within the container */
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  /* Removed sticky positioning to allow header to scroll with content */
}

.App-subHeader, .App-subHeader-2, .App-subHeader-3, .App-subHeader-4 {
  font-size: 13px;
  color: #0e0551;
  font-weight: 600;
  margin-bottom: 9px;
  text-align: justify;
  background-color: #eaf2ff; /* Light blue background for highlighting */
  padding: 6px; /* Adds space around the text */
  border-left: 4px solid #0e0551; /* Blue left border for emphasis */
  border-radius: 5px; /* Soft rounded edges */
  border-bottom: 1.5px solid #0e0551;
  border-top: 1.5px solid #0e0551;
  border-right: 1px solid #0e0551;
}

.App-subHeader-5 {
  font-size: 13px;
  color: #0e0551;
  font-weight: 600;
  margin-bottom: 9px;
  text-align: justify;
  background-color: #eaf2ff; /* Light blue background for highlighting */
  padding: 6px; /* Adds space around the text */
  border-left: 4px solid #0e0551; /* Blue left border for emphasis */
  border-radius: 5px; /* Soft rounded edges */
  border-bottom: 1.5px solid #0e0551;
  border-top: 1.5px solid #0e0551;
  border-right: 1px solid #0e0551;
}
.App-subHeader-6 {
  font-size: 20px;
  color: #333;;
  font-weight: 700;
  margin-bottom: 3px;
  margin-top: 20px;
  text-align: center;
}
.App-subHeader-7 {
  font-size: 20px;
  color: #e60808;
  font-weight: 1000;
  margin-bottom: 20px;
  text-align: center;
  background-color: #fff3cd; /* Light yellow background for highlighting */
  padding: 10px; /* Adds space around the text */
  border-radius: 5px; /* Slight rounding for a softer look */
  box-shadow: 0 0 10px rgba(230, 8, 8, 0.5); /* Glow effect */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for more emphasis */
}
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.929);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
#overlay.hidden {
  display: none;
}
.loader img {
  width: 100px;
  height: auto;
}
.loading{
  animation: grow 0.7s ease-out forwards infinite;
  transform-origin: center;
}
@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.shared-folder-text{
  font-size: medium;
  padding: 0;
  margin: 0;
}