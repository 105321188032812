body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo-container {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7); /* Slightly transparent white background */
  border-radius: 8px;
  z-index: 1100;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.logo-container img {
  width: 150px;
  height: auto;
}
.home-container {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7); /* Slightly transparent white background */
  border-radius: 8px;
  z-index: 1100;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.home-container img {
  width: 40px;
  height: auto;
}
